<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="4" class="pb-0">
                        <span class="font-weight-bold">{{item.date}} </span>
                        <span class="gold white--text px-2 rounded font-weight-medium">{{item.type}}</span>
                    </v-col>
                    <v-col cols="4" align="center">
                        <span>{{ g_i18_keys('total_salaries') }}: <span class="gold white--text rounded font-weight-bold px-2">{{item.total_salaries}}</span></span>
                    </v-col>
                    <v-col cols="4" align="right">
                        <span>{{ g_i18_keys('total_amount') }}: <span class="gold white--text rounded font-weight-bold px-3">{{item.total_amount}}</span></span>
                    </v-col>
                    <v-col cols="12" v-if="false">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" no-gutters v-if="false">
                    <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('type') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.type}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_salaries') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.total_salaries}}</v-col>
                            </v-row>
                        </v-col>
                        <v-col v-bind="layout">
                            <v-row no-gutters align="center" >
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_amount') }}</v-col>
                                <v-col cols="12" class="gold--text">{{item.total_amount}}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="'width: calc(100% /' + keys.length + ')'">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>
                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{item}}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "SalaryReportCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        card: { cols: 4, xs: 4, sm: 4, md: 4, align: 'center', class: 'my-2 full-cols'}
    }),
    computed: {
        layout() {
            return this.card;
        },
        checkRouteName() {
            return this.$route.name === 'salary_reports';
        }
    }
}
</script>